import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    textAlign: "center",
    boxShadow: "none",
  },
  outer: {
    padding: "15px",
  },
  bio: {
    fontFamily: "Work Sans",
    fontSize: "1.05rem",
  },
  headers: {
    fontFamily: "Work Sans",
    textAlign: "center",
    fontSize: "1.3rem",
  },
  buttonLink: {
    textDecoration: "none",
    fontFamily: "Work Sans",
  },
  button: {
    marginTop: "10px",
    color: "#474747",
    fontFamily: "Work Sans",
    fontSize: "1rem",
    textDecoration: "none",
    borderRadius: "30px",
  },
  media: {
    width: "200px",
    height: "200px",
    margin: "0 auto",
  },
}))

const StyledCard = withStyles({
  root: {
    background: "transparent",
  },
})(Card)

const StyledButton = withStyles({
  outlined: {
    border: "2px solid #0072ab",
  },
})(Button)

export default function ProjectCard(props) {
  const classes = useStyles()
  return (
    <div className={classes.outer}>
      <StyledCard className={classes.root}>
        <CardMedia
          component="img"
          height={props.imgHeight}
          className={classes.media}
          image={props.image}
          title={props.name}
          variant="outlined"
        />
        <CardContent>
          <Typography className={classes.headers} variant="h5" component="h2">
            {props.name}
          </Typography>
          <Typography
            className={classes.headers}
            variant="h6"
            color="textSecondary"
          >
            {props.role}
          </Typography>
          <hr style={{ border: "1px solid #0072ab ", width: "15%" }} />
          <Typography className={classes.bio} variant="body2" component="p">
            {props.bio}
          </Typography>
          {props.button && (
            <StyledButton
              component={AniLink}
              paintDrip
              hex="#ffffff"
              to={props.buttonLink}
              variant="outlined"
              className={classes.button}
            >
              {props.buttonText}
            </StyledButton>
          )}
        </CardContent>
      </StyledCard>
    </div>
  )
}
