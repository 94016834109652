import React, { useEffect } from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { Button, Grid, useMediaQuery, Container } from "@material-ui/core"
import Helmet from "react-helmet"
import ProjectCard from "../components/projectcard"
import Hero from "../components/hero"

import Microphone from "../img/microphone.svg"
import Present from "../img/present.svg"
import Book from "../img/book.svg"
import Police from "../img/police.svg"
import Events from "../img/events.svg"
import Team from "../img/team.svg"

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: "3vh",
    fontSize: "1.4rem",
    fontFamily: "Work Sans",
    textTransform: "capitalize",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  title2: {
    fontFamily: "Work Sans",
    fontSize: "2.5rem",
    color: "#474747",
    textAlign: "center",
    marginBottom: "20px",
  },
  bodyText2: {
    fontFamily: "Work Sans",
    fontSize: "1.15rem",
    color: "#474747",
    textAlign: "center",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "40%",
    },
  },
}))

export default function Home() {
  const classes = useStyles()
  const isPhone = useMediaQuery("(max-width: 601px)")

  const isTablet = useMediaQuery("(max-width: 850px)")
  const isLaptop = useMediaQuery(
    "(min-device-width: 851px) and (max-device-width: 1449px)"
  )
  let height = 250

  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js"></script>
      </Helmet>
      <>
        <Hero
          title="Home"
          bodyText="We're constantly looking for new and exciting ways to empower diversity. Here's some of what we do."
        />
        <Grid container spacing={0} alignItems="stretch" justify="center">
          <Grid item xs={isLaptop ? 10 : 9}>
            <Grid container spacing={3} alignItems="stretch" justify="center">
              <Grid item md={4} lg={isLaptop ? 4 : 3}>
                <ProjectCard
                  name="This is Me"
                  image={Microphone}
                  imgHeight={height}
                  bio="Our short film, created to raise autism awarness and spread positivity. Free showings are available to any group or school in the local area."
                  animation="fade-up"
                  button
                  buttonText="Get Involved"
                  buttonLink="/thisisme/"
                />
              </Grid>
              <Grid item md={4} lg={isLaptop ? 4 : 3}>
                <ProjectCard
                  name="Pamper Project"
                  image={Present}
                  imgHeight={height}
                  bio="The Pamper Project is designed to give back to our wonderful community. Nominees could win a luxury pamper hamper or a voucher for a salon visit!"
                  animation="fade-up"
                  button
                  buttonText="Nominate"
                  buttonLink="/pamper/"
                />
              </Grid>
              <Grid item md={4} lg={isLaptop ? 4 : 3}>
                <ProjectCard
                  name="From a Tear to Here"
                  image={Book}
                  imgHeight={height}
                  bio="Written by Dawn Avery, From a Tear to Here is a book documenting the Avery family's journey through the highs and lows of living with autism."
                  animation="fade-up"
                  button
                  buttonText="Get the Book"
                  buttonLink="/book/"
                />
              </Grid>
              <Grid item md={4} lg={isLaptop ? 4 : 3}>
                <ProjectCard
                  name="Aston's Way"
                  image={Police}
                  imgHeight={height}
                  bio="Aston's Way is a unique experience that puts you in the shoes of an autistic person, using state of the art Autism Reality Experierience buses."
                  animation="fade-up"
                  button
                  buttonText="Find out More"
                  buttonLink="/astonsway/"
                />
              </Grid>
              <Grid item md={4} lg={isLaptop ? 4 : 3}>
                <ProjectCard
                  name="Events"
                  image={Events}
                  imgHeight={height}
                  bio="We strive to give back to the community with our wonderful events. Take a look at our upcoming events, or browse through past ones!"
                  button
                  buttonText="Browse Events"
                  buttonLink="/events/"
                />
              </Grid>
              <Grid item md={4} lg={isLaptop ? 4 : 3}>
                <ProjectCard
                  name="Our Team"
                  image={Team}
                  imgHeight={height}
                  bio="None of this would be possible without our amazing team members who give their time to Team Avery. Find out more about them here! "
                  button
                  buttonText="Meet the Team"
                  buttonLink="/team/"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  )
}
